import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpClientService {

  // userLang: User defined language
  public userLang: string = "";

  // server: Development server address
  private _serverAddr: string = "https://lostandfound.chat/api/";
  // private _serverAddr: string = "http://localhost:8080/api/";

  constructor (private http: HttpClient) {
    this.userLang = navigator.language.toLowerCase();
    this.userLang = /(es-es|en-us|es-ca)/gi.test (this.userLang) ? this.userLang : 'es-es';
  }

  public get (url: string, headers: HttpHeaders) {
    return this.http.get (url, { headers: this.appendHeaders (headers) });
  }

  public post (url: string, data: any, headers: HttpHeaders) {
    return this.http.post (url, data, { headers: this.appendHeaders (headers) });
  }

  public postNoHeaders (url: string, data: any, headers: HttpHeaders) {
    return this.http.post (url, data, { headers: headers });
  }

  public patch (url: string, data: any, headers: HttpHeaders) {
    return this.http.patch (url, data, { headers: this.appendHeaders (headers) });
  }

  public delete (url: string, headers: HttpHeaders) {
    return this.http.delete (url, { headers: this.appendHeaders (headers) });
  }

  public getServerAddr () {
    return this._serverAddr;
  }

  private appendHeaders (headers: HttpHeaders) : HttpHeaders {

    let completeHeaders: HttpHeaders = headers;
    completeHeaders = completeHeaders.append ('Content-Type', 'application/json');
    completeHeaders = completeHeaders.append ('Accept-Language', this.userLang);
    completeHeaders = completeHeaders.append ('Accept', 'application/json');

    return completeHeaders;
  }

}
