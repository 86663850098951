import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './pages/01_home/home.module#HomeComponentModule' },
  { path: 'brand-detail', loadChildren: './pages/02_brand-detail/brand-detail.module#BrandDetailModule'},
  { path: 'legal', loadChildren: './pages/03_lopd/lopd.module#LOPDModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
