import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';

// Services
import {HttpClientService} from '../http-client.service';

@Injectable()
export class ContactService {
  constructor (private _http: HttpClientService) {

  }

  sendEmail (contactForm: any): Observable<void> {
    let url: string = this._http.getServerAddr().concat ('contact-repre/');
    let body: string = JSON.stringify (contactForm);
    const headers = new HttpHeaders();

    return Observable.create (observer => {
      this._http.post (url, body,  headers)
        .subscribe (
          (response: any) => {
            // Notify subscriber
            observer.next();
            observer.complete();
          },
          (error: any) => {
            observer.error (error);
            console.error ('[ChatService][getMEssages] Error getting messages:', error);
          }
        );
    });
  }
}
